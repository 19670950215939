<template>
	<div class="group/sg-input-text">
		<label v-if="placeholder && placeholderStyle === 'normal'" :for="id" class="text-j16-regular mb-2">
			{{ placeholder }}
			<span v-if="placeholderAdditional" class="text-sg-grey-200"> ({{ placeholderAdditional }})</span>
		</label>
		<div class="relative bg-white">
			<input
				:id="id"
				:name="name"
				:disabled="disabled"
				:required="required"
				:type="inputType"
				:autocomplete="autocomplete"
				:pattern="pattern"
				:maxlength="maxlength"
				:minlength="minlength"
				:value="modelValue"
				:step="step"
				:class="[
					`py-2.5 w-full text-sm md:text-base duration-300 text-gray-800 bg-transparent rounded-md border focus:outline-none focus:ring-0`,
					{
						'border-sg-red-100 focus:border-sg-red-100': hasError,
						'border-sg-grey-150 group-hover/sg-input-text:border-primary focus:border-primary': !hasError && !disabled,
						'border-sg-grey-150 opacity-60': disabled,
					},
					{
						'pl-12': icon && iconPosition === 'left',
						'pl-4': !icon || iconPosition !== 'left',
					},
					{
						'pr-12': icon && iconPosition === 'right',
						'pr-4': !icon || iconPosition !== 'right',
					},
				]"
				@input="onChange"
			/>
			<component
				:is="icon"
				v-if="icon"
				:class="[
					'absolute center-y size-5',
					{ 'left-4': iconPosition === 'left' },
					{ 'right-4': iconPosition === 'right' },
				]"
			/>
			<label
				v-if="placeholder && placeholderStyle === 'floating'"
				:class="[
					'absolute duration-300 rounded-full text-j16-regular select-none pointer-events-none',
					{
						'left-12 bottom-1/2 translate-y-1/2': icon && iconPosition === 'left' && !modelValue,
						'left-4 bottom-1/2 translate-y-1/2': (!icon || iconPosition !== 'left') && !modelValue,
					},
					{ ' left-1 bottom-full -mb-3 px-2.5 py-0.5 bg-white scale-90 text-primary': modelValue },
				]"
			>
				{{ placeholder }}
			</label>
			<button
				v-if="passwordToggleRef !== undefined"
				type="button"
				class="absolute center-y right-4"
				@click="passwordToggleRef = !passwordToggleRef"
			>
				<component :is="passwordToggleRef ? IconEyeOpen : IconEyeClosed" class="size-5" />
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { type Component, computed, ref } from 'vue';
import { IconEyeClosed, IconEyeOpen } from '@/assets/icons';

export interface InputProps {
	type?: string;
	disabled?: boolean;
	required?: boolean;
	hideRequired?: boolean;
	autocomplete?: string;
	id?: string;
	icon?: Component;
	iconPosition?: 'left' | 'right';
	passwordToggle?: boolean;
	name?: string;
	maxlength?: string;
	minlength?: string;
	placeholder?: string;
	placeholderAdditional?: string;
	placeholderStyle?: 'normal' | 'floating';
	pattern?: string;
	modelValue?: string | number;
	hasError?: boolean;
	step?: number;
}

const props = withDefaults(defineProps<InputProps>(), {
	type: 'text',
	disabled: false,
	required: false,
	name: undefined,
	id: undefined,
	icon: undefined,
	iconPosition: 'left',
	autocomplete: 'off',
	maxlength: undefined,
	minlength: undefined,
	placeholderStyle: 'normal',
	placeholder: undefined,
	placeholderAdditional: undefined,
	pattern: undefined,
	modelValue: undefined,
	hasError: false,
	step: undefined,
});

const emit = defineEmits(['update:modelValue']);

const passwordToggleRef = ref<undefined | boolean>(props.passwordToggle ? false : undefined);
const inputType = computed(() => {
	if (passwordToggleRef.value === undefined) {
		return props.type;
	}

	return passwordToggleRef.value ? 'text' : 'password';
});

function onChange(event: Event) {
	emit('update:modelValue', (event.target as HTMLInputElement).value);
}
</script>
